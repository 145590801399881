<template>
  <footer>
    <div class="site-footer">
      <div class="container">
        <div class="columns">
          <div class="column is-7">
            <p class="sub-title">
              For all support enquiries contact Cervin Limited
            </p>
            <div class="columns contacts">
              <div class="column is-3">
                <p><i class="fas fa-phone-alt"></i> 09 360 8700</p>
              </div>
              <div class="column is-7">
                <p>
                  <a style="color:white" href="mailto:sr-support@cervinmedia.co.nz"
                    ><i class="fas fa-envelope"></i>
                      sr-support@cervinmedia.co.nz</a
                  >
                </p>
              </div>
            </div>
            <div class="trade-mark">
              <p>© Cervin Limited</p>
              <p>Terms &amp; Conditions</p>
            </div>
          </div>
          <div class="column is-5">
            <img
              src="/img/logo_cervin.svg"
              alt="Cervin Limited"
              style="height: 40px; float: right"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
