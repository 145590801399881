<template>
    <form action="">
        <div class="modal-card" style="width: 900px">
            <header class="modal-card-head">
                <p class="modal-card-title">Account Details</p>
                <button
                    type="button"
                    class="delete"
                    @click="$emit('close')"/>
            </header>
            <section class="modal-card-body">
                <b-field label="Email">
                    <b-input
                        type="email"
                        placeholder="Your email"
                        required>
                    </b-input>
                </b-field>
                <b-field label="Password">
                    <b-input
                        type="password"
                        password-reveal
                        placeholder="Your password"
                    >
                    </b-input>
                </b-field>
                <b-field grouped>
                    <b-field label="First Name">
                        <b-input
                            type="text"
                            placeholder="First Name"
                            required>
                        </b-input>
                    </b-field>
                    <b-field label="Last Name">
                        <b-input
                            type="text"
                            placeholder="First Name"
                            required>
                        </b-input>
                    </b-field>
                </b-field>
                <b-field label="Position">
                    <div class="block">
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="gp">
                            GP
                        </b-radio>
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="nurse">
                            Nurse
                        </b-radio>
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="practitioner-nurse">
                            Nurse Practitioner
                        </b-radio>
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="other"
                        >
                            Other
                        </b-radio>
                    </div>
                </b-field>
                <b-field label="NZMC Register for GP's / Council Number for Registered Nurses">
                    <p>Please contact Cervin Limited to get information on Registration Numbers.</p>
                </b-field>
                <b-field label="Qualifications">
                    <b-input
                        type="text"
                        placeholder="Qualifications"
                    >
                    </b-input>
                </b-field>
                <b-field label="Bio">
                    <b-input
                        type="textarea"
                        placeholder="Bio"
                    >
                    </b-input>
                </b-field>
                <b-field label="Special Interests">
                    <b-input
                        type="textarea"
                        placeholder="Special Interests"
                    >
                    </b-input>
                </b-field>
                <b-field label="Usual Days Worked">
                    <b-input
                        type="text"
                        placeholder="Usual Days Worked"
                    >
                    </b-input>
                </b-field>

                <b-field label="Open Book">
                    <div class="block">
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="1">
                            Yes
                        </b-radio>
                        <b-radio v-model="position"
                                 name="name"
                                 native-value="0">
                            No
                        </b-radio>
                    </div>
                </b-field>

                <b-field label="Permission for publishing your information in your Health Services">

                </b-field>
                <div class="block">
                    <b-radio v-model="position"
                             name="name"
                             native-value="1">
                        Yes
                    </b-radio>
                    <b-radio v-model="position"
                             name="name"
                             native-value="0">
                        No
                    </b-radio>
                    <p class="hints">I would like this information included in my free Professional Profile on healthpages.co.nz -
                        Your email will not be published.</p>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$emit('close')">Close</button>
                <button class="button is-danger">Submit</button>
            </footer>
        </div>
    </form>
</template>
<script>
export default {

    data() {
        return {
            position: "gp"
        }
    }
}
</script>
<style scoped>
.hints{
    font-size: 14px;
}
</style>
